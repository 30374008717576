<template>
  <el-form :model="form" ref="form" :rules="rules">
    <div class="form-list">
      <el-form-item :class="{ 'code-sended': isClickSendEmail }" prop="code">
        <div class="self_label"><span>*</span>{{ $t('common.field.enterVerificationCode') }}</div>
        <div class="verification-code-container">
          <div class="verification-code-item">
            <el-input v-model="form.code" id="verificationCode" class="plain-input" data-testid="code" />
          </div>
          <div class="verification-code-item">
            <el-button class="el-button btn-blue" :disabled="isSendEmail" @click="sendEmail()" data-testid="code-button">
              {{ isClickSendEmail ? $t('common.button.resendCode') : $t('common.button.sendCode') }}
              {{ displayTime > 0 ? `(${displayTime})` : '' }}
            </el-button>
          </div>
        </div>
      </el-form-item>
    </div>
    <div class="warn_text " v-if="errorCode !== 0">
      {{ $t(`responseMsg.${errorCode}`) }}
    </div>
    <div class="primary_text" v-if="isClickSendEmail">
      {{
        $t('withdraw.otherMethod.verificationCode', {
          email: getEmail,
          supportEmail: getSupportEmail
        })
      }}
    </div>
  </el-form>
</template>

<script>
import { apiSendEmailVerificationCode } from '@/resource';

export default {
  name: 'VerificationCode',
  props: {
    namespace: String,
    token: {
      type: String,
      default: null
    },
    maskedEmail: {
      type: String,
      default: null
    },
    callbackRegulator: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: {
        code: ''
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t('common.formValidation.verCodeReq'),
            trigger: 'blur'
          }
        ]
      },
      isSendEmail: false,
      isClickSendEmail: false,
      secondTime: 60,
      displayTime: 0,
      timeInterval: null,
      errorCode: 0,
      txId: ''
    };
  },
  computed: {
    getEmail() {
      return this.maskedEmail ? this.maskedEmail : this.$store.state.common.emailMasked;
    },
    getSupportEmail() {
      return this.callbackRegulator ? this.$config.info.getEmail(this.callbackRegulator) : this.GLOBAL_CONTACT_EMAIL;
    }
  },
  methods: {
    sendEmail() {
      apiSendEmailVerificationCode({ namespace: this.namespace, token: this.token }).then(resp => {
        this.isSendEmail = true;
        this.isClickSendEmail = true;
        this.startTimer();

        switch (resp.data.code) {
          case 0:
            this.txId = resp.data.data.txId;
            break;
          case 530:
            this.$router.push({ name: 'login' });
            break;
          default:
            this.errorCode = resp.data.code;
            break;
        }
      });
    },
    startTimer() {
      this.displayTime = this.secondTime;
      this.timeInterval = setInterval(this.countDown, 1000);
    },
    countDown() {
      this.displayTime--;
      if (this.displayTime === 0) this.stopTimer();
    },
    stopTimer() {
      clearInterval(this.timeInterval);
      this.isSendEmail = false;
    },
    getFormDataOfChild() {
      return {
        txId: this.txId,
        code: this.form.code
      };
    },
    submitForm() {
      return this.$refs['form'].validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.verification-code-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .verification-code-item:nth-child(1) {
    flex: 1;
  }

  .verification-code-item+.verification-code-item {
    @include rtl-sass-prop(margin-left, margin-right, 8px);
  }
}

/deep/ .btn-blue {
  margin-top: 0 !important;
}

.warn_text {
  font-size: 12px;
  color: $danger;
}

.primary_text {
  font-size: 12px;
  color: $text-secondary;
  margin-top: 8px;
}
</style>
